<template lang="pug">
    .content__main
        .analyze
            loading(v-if="loading")._loading
            template(v-else)
                choose-want(:type="decorationType" :outerWaveEnter="true" :outerLineWave="true" :innerFillWave="true" :innerWave="!photoCompareStatus" :sections="true")
                person-description._description-wrap(v-if="!photoCompareStatus" @change-decoration-type="changeDecorationType")
                compare-with-photo(v-else :service="activeService")._compare-with-photo
</template>

<script>
import PersonDescription from "@/components/person/person-description/person-description";
import ChooseWant from "@/components/auth/choose-want/choose-want";
import gql from "graphql-tag";
import personGetDescriptionQuery from "@/graphql/queries/personGetDescription.query.graphql";
import getPersonQuery from "@/graphql/queries/getPerson.query.graphql";
import CompareWithPhoto from "@/components/person/compare-with-photo/compare-with-photo";

export default {
    name: "Analyze",
    components: { CompareWithPhoto, ChooseWant, PersonDescription },
    data() {
        return {
            loading: true,
            activeService: "",
            photoCompareStatus: false,
            decorationType: "person"
        };
    },
    async created() {
        await this.$store.dispatch("setPage", "analyze");
        await this.$store.dispatch("person/clearPerson");
        await this.getPerson();
        await this.$apollo
            .query({
                query: gql(personGetDescriptionQuery),
                variables: {
                    uuid: this.myPerson.uuid,
                },
            })
            .then((r) => {
                this.$store.dispatch(
                    "person/setPersonDescription",
                    r.data.personGetDescription,
                );
            });
        this.loading = false;

        this.$bus
            .$on("showPhotoCompare", (service) => {
                // service name передаётся в сравнение по фото, чтобы пользователь видел, в каком разеделе он покупает услугу
                this.activeService = service;
                this.photoCompareStatus = true;
            })
            .$on("backToPersonDescription", () => {
                this.photoCompareStatus = false;
            });
    },
    destroyed() {
        this.$bus.$off("showPhotoCompare").$off("backToPersonDescription");
    },
    methods: {
        changeDecorationType(type) {
            this.decorationType = type;
        },
        async getPerson() {
            await this.$apollo
                .query({
                    query: gql(getPersonQuery),
                    variables: {
                        uuid: this.myPerson.uuid,
                    },
                })
                .then(async (r) => {
                    await this.$store.dispatch(
                        "person/setPerson",
                        r.data.getPerson,
                    );
                });
        },
    },
};
</script>

<style lang="scss">
.analyze {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 150px);

    @media(max-width: 991px) {
        height: initial;
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__description-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media (max-width: 991px) {
            position: initial;
            height: initial;
        }
    }

    &__compare-with-photo {
        //margin-top: 80px;
    }
}
</style>
